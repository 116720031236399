import React from 'react';
import Particles from 'react-particles-js';

const PARTICLES_PARAMS = {
    particles: {
        number: {
            value: 300,
            density: {
                enable: true,
                value_area: 500
            }
        },
        size: {
            value: 1.5,
            random: true,
        },
        line_linked: {
            enable: false,
        },
        interactivity: {
            events: {
                resize: true
            }
        }
    }
};

const Background = () => (
    <Particles
        className="particles-bg"
        params={PARTICLES_PARAMS}
    />
);


export default Background;