import React, {Component, Fragment} from 'react';
import Background from "./components/Background";
import Card from "./components/Card";
import logo from "./assets/logo.png";
import Footer from "./components/Footer";

const EMAIL = "we.are@investedin.digital";
const COPYRIGHT = "investedin.digital © 2022";

class App extends Component {
    constructor(props) {
        super(props);
        this.state= {
            isParticlesVisible:true
        }
    }

    render() {
        return (
            <Fragment>
                {this.state.isParticlesVisible && <Background/>}
                <Card email={EMAIL}
                      image={logo}/>
                <Footer copyright={COPYRIGHT}/>
            </Fragment>
        )
    };
}

export default App;
